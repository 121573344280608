<template>
  <div class="input-with-label">
    <div class="input-with-label__label label-title" :class="{ required }">{{ label }}</div>
    <input
      v-validate.disable="validationRules"
      class="input-with-label__input rounded-input input-text input-focus-within"
      :value="value"
      :name="name"
      :type="type"
      :class="{ 'error-highlight': hasError }"
      @input="handleInput"
    />
    <field-error v-if="hasEmailError" class="field-error__email" :error-message="$t('dashboard.warning.provideValidEmail')" />
    <field-error v-else-if="hasError" :error-message="$t('dashboard.warning.requiredField')" />
  </div>
</template>

<script>
import { isEmpty } from 'lodash';
import FieldError from '@/modules/dashboard/components/common/FieldError';

const INPUT_TYPES = {
  TEXT: 'text',
  EMAIL: 'email'
};

export default {
  name: 'InputWithLabel',
  components: { FieldError },
  inject: ['$validator'],
  props: {
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    },
    name: {
      type: String,
      default: 'name'
    }
  },
  computed: {
    validationRules() {
      return {
        required: this.required,
        email: this.isEmailType
      };
    },
    isEmailType() {
      return this.type === INPUT_TYPES.EMAIL;
    },
    hasError() {
      return this.errors.has(this.name);
    },
    hasEmailError() {
      return this.isEmailType && this.hasError && !isEmpty(this.value);
    }
  },
  methods: {
    handleInput({ target: { value } }) {
      this.$emit('input', value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors.scss';
@import '../../../../assets/scss/common/text.scss';
@import '../../../../assets/css/common/inputs.css';
@import '../../../../assets/css/common/errors.css';

.input-with-label {
  width: 100%;

  &__input {
    padding: 8px 10px 10px 10px;
    width: 100%;
  }

  &__label.required:after {
    color: $error-color;
    content: ' *';
  }
}

@media (max-width: 767px) {
  .input-with-label {
    &__input {
      margin-bottom: 10px;
    }
  }
}
</style>
