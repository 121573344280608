<template>
  <main class="information">
    <mobile-header>
      <span slot="header-title">{{ $t('dashboard.label.contactInformation') }}</span>
    </mobile-header>
    <section class="information__section">
      <div class="information__description">
        {{ $t('dashboard.label.contactInformationReadyRedeem') }}
      </div>
      <div class="information__participant-inputs">
        <div class="participant__email-container flex-column">
          <input-with-label
            v-model="participantEmail"
            type="email"
            name="email"
            :label="$t('dashboard.label.participantsName', [participantName])"
            required
            @input="updateField(participantEmail, $event)"
          />
        </div>
        <div class="participant__phone-container">
          <div class="input__label">
            {{ $t('dashboard.label.participantsPhone', [participantName]) }}
          </div>
          <phone-edit-field
            :phone="participantPhone"
            :iso-code="participantPhoneIsoCode"
            :phone-field-name="participantPhoneFieldName"
            class="input__phone"
            @update-iso-code="updateField('participantPhoneIsoCode', $event)"
            @update-phone-number="updateField('participantPhone', $event)"
          />
        </div>
      </div>
      <section class="information__doctor-phone-section">
        <div>
          <div class="flex" :class="{ mb15: !hasPrivatePhoneNumber }">
            <span class="doctor__info-icon fas fa-exclamation-triangle mr10"></span>
            <div v-if="!hasPrivatePhoneNumber" class="doctor__info-text">
              {{ $t('dashboard.label.redeemDoctorsPhoneShouldBeProvided') }}
            </div>
            <div v-else class="doctor__info-text">
              {{ $t('dashboard.label.redeemDoctorsPhoneIsKnown', [getPrivatePhoneNumber()]) }}
            </div>
          </div>
          <div v-if="!hasPrivatePhoneNumber" class="doctor__phone-container">
            <div class="input__label doctor__phone-label">
              {{ $t('dashboard.loyalty.label.phoneNumber') }}
              <span class="compulsory">*</span>
            </div>
            <phone-edit-field
              :phone="privatePhone"
              :iso-code="privatePhoneIsoCode"
              :phone-field-name="privatePhoneFieldName"
              class="input__phone"
              is-required
              @update-iso-code="updateField('privatePhoneIsoCode', $event)"
              @update-phone-number="updateField('privatePhone', $event)"
            />
          </div>
        </div>
      </section>
    </section>
    <mobile-fixed-footer class="information__footer flex--center--center">
      <template slot="footer">
        <button class="rounded-button-orange rounded-button-orange--filled" @click="onConfirm">
          Confirm
        </button>
      </template>
    </mobile-fixed-footer>

    <sms-sent-modal @sms-sent-ok="redirectToLoyaltyProgramPage" />
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import MobileHeader from '@/modules/dashboard/components/common/MobileHeader';
import PhoneEditField from '@/modules/dashboard/components/profile/PhoneEditField';
import MobileFixedFooter from '@/modules/dashboard/components/dashboard/common/MobileFixedFooter';
import SmsSentModal from '@/modules/dashboard/components/loyalty-program/SmsSentModal';
import InputWithLabel from '@/modules/dashboard/components/common/InputWithLabel';

import {
  getLoyaltyProgramParticipant,
  getParticipantStatistics,
  sendRedeemRequest
} from '@/modules/dashboard/api';

import { getPhoneNumber } from '@/utils';
import { isMobileDevice } from '@/modules/dashboard/utils';
import UUIDService from '@/modules/dashboard/services/uuidService';

import rootTypes from '@/store/types';

import {
  REDEEM_TYPES,
  REDEEM_OPERATIONS,
  DEFAULT_ISO_CODE
} from '@/modules/dashboard/api/constants';

const REDEEM_TYPE_TO_STATISTIC_FIELD_BINDING = {
  [REDEEM_TYPES.GIFT_CARD_REDEEM]: 'giftCardCost',
  [REDEEM_TYPES.FREE_PRODUCT_REDEEM]: 'freeProductsCost'
};

export default {
  name: 'ContactInformation',
  components: { MobileHeader, PhoneEditField, MobileFixedFooter, SmsSentModal, InputWithLabel },
  props: {
    participantId: {
      type: Number,
      required: true
    },
    redeemType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      participant: null,
      participantStatistic: null,
      participantEmail: '',
      participantPhoneFieldName: '',
      participantPhoneIsoCode: DEFAULT_ISO_CODE,
      participantPhone: '',
      privatePhoneFieldName: '',
      privatePhoneIsoCode: DEFAULT_ISO_CODE,
      privatePhone: '',
      hasPrivatePhoneNumber: false
    };
  },
  computed: {
    ...mapGetters({
      user: rootTypes.getters.GET_USER,
      userId: rootTypes.getters.GET_USER_ID
    }),
    pointsToRedeem() {
      const statisticField = REDEEM_TYPE_TO_STATISTIC_FIELD_BINDING[this.redeemType];

      return this.participantStatistic ? this.participantStatistic[statisticField] : 0;
    },
    participantName() {
      return this.participant ? this.participant.name : '';
    }
  },
  created() {
    this.participantPhoneFieldName = `phone-${UUIDService.v4()}`;
    this.privatePhoneFieldName = `phone-${UUIDService.v4()}`;
  },
  async mounted() {
    this.setLoading(true);

    const requestParams = [this.userId, this.participantId];

    const getParticipantPromise = getLoyaltyProgramParticipant(...requestParams);
    const getParticipantStatisticPromise = getParticipantStatistics(...requestParams);

    const { officePhoneIso, privatePhoneIso, privatePhone } = this.user;
    const initialPhoneFieldsIsoCode = privatePhone ? privatePhoneIso : officePhoneIso;

    this.participantPhoneIsoCode = initialPhoneFieldsIsoCode;
    this.privatePhoneIsoCode = initialPhoneFieldsIsoCode;

    if (privatePhone) {
      this.hasPrivatePhoneNumber = true;

      this.privatePhone = privatePhone;
    }

    const { data } = await getParticipantPromise;
    this.participant = data;
    this.participantStatistic = (await getParticipantStatisticPromise).data;
    this.setLoading(false);
  },
  methods: {
    ...mapActions({
      setLoading: rootTypes.actions.SET_LOADING
    }),
    getPrivatePhoneNumber() {
      return getPhoneNumber(this.privatePhoneIsoCode, this.privatePhone);
    },
    updateField(fieldName, value) {
      this[fieldName] = value;
    },
    async onConfirm() {
      if (!(await this.$validator.validateAll())) {
        return;
      }

      this.setLoading(true);

      const { id, name } = this.participant;

      const participantPhoneData = this.participantPhone
        ? { phoneIsoCode: this.participantPhoneIsoCode, phone: this.participantPhone }
        : {};

      let privatePhoneData = {};

      if (!this.hasPrivatePhoneNumber) {
        privatePhoneData = {
          supposedDoctorsPrivatePhoneIsoCode: this.privatePhoneIsoCode,
          supposedDoctorsPrivatePhone: this.privatePhone
        };
      }

      await sendRedeemRequest(this.userId, {
        ...participantPhoneData,
        ...privatePhoneData,
        id,
        email: this.participantEmail,
        name,
        invokedOperation: REDEEM_OPERATIONS[this.redeemType],
        pointsToRedeem: this.pointsToRedeem
      });

      if (!isMobileDevice()) {
        this.$emit('update-paticipant-list');
      }

      this.setLoading(false);

      this.$modal.hide('contact-information-modal');
      this.$modal.show('sms-sent-modal');
    },
    redirectToLoyaltyProgramPage() {
      this.$router.push({ name: 'LoyaltyProgram' });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/css/common/inputs';
@import '../../../../assets/css/common/icons';
@import '../../../../assets/scss/common/buttons';
@import '../../../../assets/scss/helpers/colors';

.information {
  &__section {
    height: 580px;
    padding: 30px;
  }

  &__description {
    margin-bottom: 30px;
    font-size: 15px;
    line-height: 17px;
    color: $text-color;
  }

  &__participant-inputs {
    margin-bottom: 30px;
  }

  &__doctor-phone-section {
    padding: 15px;
    background: $beige;
    border-radius: 8px;
  }

  &__footer {
    height: 70px;
    border-top: 1px solid $dim-white;
  }
}

.participant {
  &__email-container {
    width: 300px;
  }
}

.input {
  &__label {
    margin-bottom: 10px;
    font-size: 13px;
    font-weight: 300;
    color: $text-color;
  }

  &__email {
    margin-bottom: 10px;
  }

  &__phone {
    /deep/ & .phone-number__container {
      max-width: 100%;
    }
  }
}

.doctor {
  &__info-icon {
    color: $pale-orange-color;
  }

  &__info-text {
    font-size: 13px;
    line-height: 15px;
    color: $text-color;
    font-weight: 300;
  }

  &__phone-container {
    margin-left: 26px;
  }

  &__sms-error {
    margin-left: 26px;
    font-size: 13px;
    font-weight: 300;
    line-height: 19px;
    color: $error-color;
  }
}

@media (max-width: 767px) {
  .information {
    height: 100vh;

    &__section {
      height: 100%;
      padding: 70px 20px 150px;
      background-color: $white;
    }

    &__description {
      margin-bottom: 20px;
      font-size: 13px;
      line-height: 15px;
    }

    &__footer {
      height: 60px;
      background-color: $white;
    }
  }

  .participant {
    &__email-container {
      width: 100%;
    }
  }

  .input {
    &__label {
      margin-bottom: 5px;
      font-size: 12px;
    }

    &__email {
      width: 100%;
    }
  }

  .doctor {
    &__info-icon {
      align-self: center;
    }

    &__info-text {
      font-size: 11px;
      line-height: 13px;
    }

    &__phone-container {
      margin-left: 0;
    }

    &__phone-label {
      font-size: 13px;
      margin-bottom: 10px;
    }
  }
}
</style>
