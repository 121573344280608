<template>
  <modal
    name="sms-sent-modal"
    :classes="['modal-window']"
    transition="scale"
    scrollable
    adaptive
    :click-to-close="false"
    :width="280"
    height="auto"
  >
    <div class="modal-container">
      <div class="modal__text mb30">
        {{ $t('dashboard.label.verificationSms') }}
      </div>
      <div class="flex--center--center">
        <button class="modal__button rounded-button-gray" @click="onOkClick">
          {{ $t('dashboard.button.ok') }}
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'SmsSentModal',
  methods: {
    onOkClick() {
      this.$emit('sms-sent-ok');
      this.$modal.hide('sms-sent-modal');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/common/buttons';
@import '../../../../assets/scss/helpers/colors';

.modal {
  &-container {
    padding: 30px 20px;
  }

  &__text {
    font-size: 13px;
    font-weight: 300;
    line-height: 19px;
    color: $text-color;
    text-align: center;
  }
}
</style>
