<template>
  <div ref="dropdown" class="dropdown">
    <div class="dropdown__button-wrapper" @click="toggleDropdownVisibility">
      <slot name="button"></slot>
    </div>
    <ul
      ref="scrollableDropdown"
      class="dropdown-menu main-dropdown scrollable-dropdown"
      :class="dropdownMenuClasses"
    >
      <slot name="list-elements"></slot>
    </ul>
  </div>
</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar';

import { legacyDropdownMixin } from '@/modules/dashboard/mixins/dropdown/legacyDropdownMixin';

import { updateDropdownVisibility } from '@/modules/dashboard/utils';
import { addPixelsPostfix } from '@/modules/questionnaire/utils';

const DROPDOWN_TOP_OFFSET = 2;

export default {
  name: 'ScrollableDropdown',
  mixins: [legacyDropdownMixin],
  props: {
    isAutoPositioned: {
      type: Boolean,
      default: true
    },
    selectElementHeight: {
      type: Number,
      default: 40
    },
    alignDropdownRight: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      scrollbar: null
    };
  },
  computed: {
    dropdownMenuClasses() {
      return {
        'dropdown-menu-right': this.alignDropdownRight,
        'dropdown-menu-left': !this.alignDropdownRight,
        'scrollable-dropdown--opened': this.isDropdownOpened
      };
    }
  },
  methods: {
    setupScrollbar() {
      this.scrollbar = new PerfectScrollbar(this.$refs.scrollableDropdown, {
        suppressScrollX: true,
        wheelPropagation: false
      });
    },
    updateDropdownPositioning() {
      const {
        dropdown: dropdownContainerRef,
        scrollableDropdown: scrollableDropdownRef
      } = this.$refs;

      const elementHeight = scrollableDropdownRef.clientHeight;
      const updatedDropdownTopValue = -(DROPDOWN_TOP_OFFSET + elementHeight);

      updateDropdownVisibility({
        dropdownContainer: dropdownContainerRef,
        dropdown: scrollableDropdownRef,
        isVisible: this.isDropdownOpened,
        elementHeight,
        top: addPixelsPostfix(updatedDropdownTopValue),
        initialTop: addPixelsPostfix(this.selectElementHeight)
      });
    },
    async toggleDropdownVisibility(event) {
      this.toggleDropdown(event);

      if (this.isAutoPositioned) {
        await this.$nextTick();
        this.updateDropdownPositioning();
      }

      this.setupScrollbar();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/css/common/dropdowns.css';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import '../../../../assets/css/common/perfect-scrollbar-styles.css';

.scrollable-dropdown {
  padding: 8px 0 15px;
  width: 100%;
  max-height: 230px;
  overflow: hidden;

  &--opened {
    display: block;
  }

  & > li {
    padding: 0;
  }
}

.main-dropdown.dropdown-menu-right {
  right: -10px;
}

/deep/ .ps__rail-y {
  opacity: 1;
  transform: scaleX(1.5);
}
</style>
